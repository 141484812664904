#contact-container{
  display: flex;
  flex-direction: row;
  margin-top: 10vh;
  height: 100%;
  max-height: 500px;
}

#contact-picture{
  background: url("../images/contact_picture.png") no-repeat;
  background-position: center;
  background-size: contain;
  height: auto;
  width: 50%;
  resize: both;
}

#contact-links{
  margin-left: 40px;
  margin-right: auto;
  margin-top: 50px;
  text-align: left;
  width: 50%;

  a{
    color: #555555;
    font-size: 1.2em;
  }

  h2{
    font-size: 1.6em;
    font-weight: normal;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 760px) {
  #contact-container{
    flex-direction: column;
    margin-top: 30px;
  }

  #contact-picture{
    height: 50%;
    margin: auto;
    background-size:contain;
  }

  #contact-links{
    margin: auto;
    text-align: center;
    width: 100%;
  }
}
