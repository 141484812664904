#reviews-container{
  h2{
    font-size: 1.6em;
    font-weight: normal;
    text-align: center;
    margin-bottom: 25px;
  }

  #reviews{
    display: flex;
    flex-direction: row;

    .review{
      background-color: #e6dfd0;
      border-radius: 10px;
      margin: auto;
      padding: 1%;
      width: 30%;

      p{
        margin-bottom: 0;
      }
  
      .client-name{
        margin: 0;
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: 810px) {
  #reviews-container{
    height: 100%;

    h2{
      font-size: 1.6em;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    #reviews{
      align-items: center;
      flex-direction: column;
      height: 100%;
      
      .review{
        margin: 1%;
        width: 90%;

        p{
          margin-top: 5px;
        }
      }
    }
  }
}