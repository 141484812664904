#services-list{
  margin-top: 150px;
  display: flex;
  flex-direction: row;
}

.service{
  text-align: center;
  margin: 0 40px;
  width: 33%;

  p {
    margin-bottom: 50px;
  }

  .book-button{
    background-color: #a67a5b;
    border-radius: 10px;
    color: #e6dfd0;
    margin-top: 50px;
    padding: 15px 50px;
  }

  .image{
    border-radius: 10%;
    height: 100%;
    margin: auto;
    min-width: 50%;
  }
}

#online-fitness{
  background: url('../images/online_fitness.jpg') no-repeat;
  background-position-x: 60%;
  background-position-y: 40%;
  background-size: cover;
}

#nutrition-only{
  background: url('../images/nutrition_only.jpg') no-repeat;
  background-size: cover;
  background-position-x: 60%;
  background-position-y: 70%;
}

#fitness-and-nutrition{
  background: url('../images/fitness_and_nutrition.jpg') no-repeat;
  background-position-x: 60%;
  background-position-y: 90%;
  background-size: cover;
}

@media screen and (max-width: 925px) {
  #services-list{
    flex-direction: column;
    margin: auto;
    margin-top: 30px;
  }

  .service{
    margin: auto;
    margin-bottom: 60px;
    width: 70%;

    h3{
      margin-top: 10px;
      margin-bottom: 0;
    }
    p{
      margin-top: 5px;
      margin-bottom: 30px;
    }
  }

  .image{
    min-height: 100px;
    width: 100px;
  }
}