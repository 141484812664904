#about-container{
  margin: auto;
  width: 50%;

  h1{
    font-family: 'Birthstone', 'Ariel';
    font-size: 4em;
    margin-bottom: 10px;
    font-weight: normal;
  }

  p{
    margin-top: 0;
  }
}

#about-text-container{
  color: #555555;
  font-size: 1.15em;
  letter-spacing: 0.01em;
  line-height: 125%;
}

#greenland-image{
  background: url('../images/greenland.jpeg') no-repeat;
  background-size: cover;
  background-position-x: 50%;
  height: 265px;
  resize: both;
}

@media screen and (max-width: 900px) {
  #about-container{
    width: 90%;
  }
}

@media screen and (max-width: 400px) {
  #about-container{
    width: 100%;
  }

  #about-text-container{
    margin: auto;
    width: 90%
  }
}