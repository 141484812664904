#home-elements {
  display: flex;
  flex-direction: row-reverse;
  height: calc(100vh - 80px);
  width: 100%;
}

#home-text{
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 15vh;
  font-size: 1.35em;
  width: 50%;
  p {
    margin: 10px;
    width: 50%;
  }

  .name{
    font-family: 'Antic Didone', Times, serif;
    font-size: 1.8em;
    font-weight: normal;
    padding-bottom: 20px;
  }

  a{
    color: #e6dfd0;
  }
}

#services-button{
  background-color: #a67a5b;
  border-radius: 10px;
  margin-top: 50px;
  padding: 15px 50px;
}

#home-image-container{
  width: 50%;
}

#home-image{
  background: url('../images/homepage_image.jpg') top right no-repeat;
  border-radius: 90% 60%;
  background-position-x: 100%;
  background-position-y: 100%;
  background-size: cover;
  height: 90%;
  margin: auto;
  width: 90%;
}

@media screen and (max-width: 600px){
  #home-elements{
    flex-direction: column;
    position: absolute;
    top: 20px;
  }

  #home-image-container{
    height: 50%;
    min-height: 150px;
    width: 100%;
  }

  #home-image{
    border-radius: 15px;
    background-position-y: 60%;

  }

  #home-text{
    padding-top: 0;
    width: 100%;

    .name{
      display: none;
    }
  }

  #services-button{
    margin-top:20px;
  }
}

@media screen and (max-width: 420px){
  #home-elements {
    height: calc(100vh - 100px);
  }
}