
#name-link{
  color: black;
  text-decoration: none;
}

#header-bar{
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-top: 10px;
  height: 70px;
}

#title{
  display: block;
  width: 30%;
  text-align: center;
  justify-content: center;
  a{
    font-family: 'Antic Didone', Times, serif;
    font-size:1.7em;
    color: #333333;
  }
}

#web-menu{
  display: flex;
  flex-wrap: nowrap;
  width: 35%;
  float: left;

  a{
    color: #333333;
    font-size: 1em;
    margin: 0 15px 0 15px;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border-bottom .2s linear;

    &.selected{
      color: #222222;
    }
    &:hover,
    &.selected {
      color: #222222;
      border-bottom: 1px solid #444444;
      text-decoration: none;
    }
  }
}

#mobile-menu{
  display: none;
}

@media screen and (max-width: 1240px){
  #web-menu{
    display: none;
  }

  #title{
    width: 60%;
    margin: auto;
  }

  .title-spacer{
    min-width: 70px;
    width: 20%;
  }

  #mobile-menu{
    display: inline;
    min-width: 70px;
    height: 100%;
    width: 20%;
    z-index: 1;

    button{
      border:none;
      background-color: inherit;
      width: 100%;
      height: 100%;
    }

    &.closed{
      .nav-options{
        display: none;
      }
    }

    &.open{
      button{
        background-color: #e6dfd0;
        border-radius: 10px 10px 0 0;
      }

      .nav-options{
        background-color: #e6dfd0;
        border-radius: 0 25px 25px 25px;
        display: flex;
        position: fixed;
        width: 300px;
        flex-direction: column;
        padding: 30px 0;

        a{
          padding: 30px 20px;
          color: #333333;
          font-size: 1em;
          text-decoration: none;
          transition: background-color .2s linear;

          &.selected{
            color: #222222;
          }
          &:hover,
          &.selected {
            color: #222222;
            background-color: #ded4c0;
            text-decoration: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 420px){
  #header-bar{
    margin-bottom: 20px;
  }
}